enum FILTER_FIELD_KEY {
  ApplicantIds = 'ids',
  Name = 'name',
  SupplierIds = 'supplierIds',
  OrganizationalUnitIds = 'organizationalUnitIds',
  FirstContactedFrom = 'firstContactedFrom',
  FirstContactedTo = 'firstContactedTo',
}

export { FILTER_FIELD_KEY }
