
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiMagnify, mdiPlus, mdiFilter, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'
import { useFilter } from '@/composables/useFilter'

import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useDeleteApplicant, useGetApplicants, useGetApplicantsBasic } from '@/api/applicant'
import { useGetSuppliersBasic } from '@/api/supplier'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'

import { ApplicantOutput } from '@/api/types/applicant'
import { FILTER_FIELD_KEY } from '@/views/acquisition/views/applicants/types'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'Applicants',
  components: {
    CommonTableView,
    AddEditApplicantDialog: () =>
      import('@/views/acquisition/views/applicants/components/AddEditApplicantDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    ApplicantsFilterBar: () => import('@/views/acquisition/views/applicants/components/ApplicantsFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const TABLE_HEADERS = [
      {
        text: root.$t('acquisition.applicants.table.col.title.id'),
        value: 'id',
        sortable: true,
      },
      {
        text: root.$t('acquisition.applicants.table.col.title.firstName'),
        value: 'firstName',
        sortable: true,
      },
      {
        text: root.$t('acquisition.applicants.table.col.title.lastName'),
        value: 'lastName',
        sortable: true,
      },
      {
        text: root.$t('acquisition.applicants.table.col.title.birthdate'),
        value: 'birthdate',
        sortable: true,
      },
      {
        text: root.$t('acquisition.applicants.table.col.title.firstContacted'),
        value: 'firstContacted',
        sortable: true,
      },
      {
        text: root.$t('acquisition.applicants.table.col.title.supplier'),
        value: 'supplier.name',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applicants.table.col.title.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applicants.table.col.title.qualifications'),
        value: 'qualifications',
        sortable: true,
      },
      {
        text: root.$t('acquisition.applicants.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applicants.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof ApplicantOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'phonePrivate',
      'phoneMobile',
      'phoneCorporate',
      'emailPrivate',
      'emailBusiness',
      'emailCorporate',
      'address1',
      'address2',
      'addressZipCode',
      'addressCity',
      'addressCountry',
      'dateForDeletion',
    ]

    const isAddEditDialogOpen = ref(false)

    const isEditMode = ref(false)

    const activeApplicant = ref<ApplicantOutput | null>(null)

    function onClickEdit(_, { item: applicant }: { item: ApplicantOutput }): void {
      if (hasSufficientRights(Rights.APPLICANT_UPDATE) && hasSufficientRights(Rights.BASIC_READ)) {
        isEditMode.value = true

        isAddEditDialogOpen.value = true

        activeApplicant.value = { ...applicant }
      }
    }

    const isDeleteDialogOpen = ref(false)

    async function onClickDelete(applicant: ApplicantOutput): Promise<void> {
      activeApplicant.value = { ...applicant }

      isDeleteDialogOpen.value = true
    }

    const { deleteApplicant } = useDeleteApplicant()

    async function onDeleteApplicant(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteApplicant(activeApplicant.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteDialogOpen.value = false

      activeApplicant.value = null

      debouncedCb.value()
    }

    const isRowInfoDialogOpen = ref(false)

    function onClickInfo(applicant: ApplicantOutput): void {
      activeApplicant.value = { ...applicant }

      isRowInfoDialogOpen.value = true
    }

    function onCloseAddEditDeleteInfoDialog(): void {
      isAddEditDialogOpen.value = false

      isDeleteDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isEditMode.value = false

      activeApplicant.value = null
    }

    const { exec: getSuppliersBasic, data: suppliers, isLoading: isLoadingSuppliersBasic } = useGetSuppliersBasic()

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()

    const {
      exec: getApplicantsBasic,
      data: applicantsBasic,
      isLoading: isLoadingApplicantsBasic,
    } = useGetApplicantsBasic()

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!suppliers.value?.length && !orgUnits.value?.length && !applicantsBasic.value?.length) {
          getSuppliersBasic()
          getOrgUnitsBasic()
          getApplicantsBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.ApplicantIds,
        value: null,
        label: root.$t('acquisition.applicants.filters.applicantIds'),
        items: computed(() => applicantsBasic.value ?? []),
        isLoading: computed(() => isLoadingApplicantsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.Name,
        value: null,
        label: root.$t('acquisition.applicants.filters.name'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.SupplierIds,
        value: null,
        label: root.$t('acquisition.applicants.filters.supplier'),
        items: computed(() => suppliers.value ?? []),
        isLoading: computed(() => isLoadingSuppliersBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIds,
        value: null,
        label: root.$t('acquisition.applicants.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.FirstContactedFrom,
        value: null,
        label: root.$t('acquisition.applicants.filters.firstContactedFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.FirstContactedTo,
        value: null,
        label: root.$t('acquisition.applicants.filters.firstContactedTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    setSort(vuetifyTableOptions, { by: 'lastName', desc: false })

    const {
      exec: getApplicants,
      data: applicants,
      isLoading: isLoadingApplicants,
      paginationResponse,
    } = useGetApplicants()

    const totalApplicants = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getApplicants({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: applicantNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<ApplicantOutput>(applicants, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: applicantTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<ApplicantOutput>(applicants, () => init(filterFieldsObject.value))

    return reactive({
      icons: {
        mdiMagnify,
        mdiPlus,
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        FILTER_FIELDS,
      },
      state: {
        applicants,
        isLoadingApplicants,

        isAddEditDialogOpen,
        isEditMode,

        activeApplicant,

        isDeleteDialogOpen,

        isRowInfoDialogOpen,

        isNotesDialogOpen,
        applicantNotes,
        notesEntityId,

        isTasksDialogOpen,
        applicantTasks,
        tasksEntityId,

        isFilterDropdownOpen,

        vuetifyTableOptions,
        totalApplicants,
      },
      listeners: {
        onClickEdit,

        onClickDelete,
        onDeleteApplicant,

        onClickInfo,

        onCloseAddEditDeleteInfoDialog,

        onOpenNotesDialog,
        onReloadNotes,

        onOpenTasksDialog,
        onReloadTasks,

        onToggleFilterDropdown,
      },
      functions: {
        debouncedCb,

        hasSufficientRights,
      },
    })
  },
})
